{/* <iframe
      title="<%= @meta_title %>"
      src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fomarisquinho%2Fvideos%2F<%= block['code'] %>%2F&show_text=false&width=560&t=0"
      style="height: 100%; width: 100%"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe> */}

const videos = document.getElementsByClassName('facebook-watch-video');

for (const video of videos) {
    const height = video.clientHeight;
    const width = video.clientWidth;
    const iframe = document.createElement('iframe');
    iframe.src = `https://www.facebook.com/plugins/video.php?width=${width}&height=${height}&href=https%3A%2F%2Fwww.facebook.com%2Fomarisquinho%2Fvideos%2F${video.dataset.code}%2F&show_text=false&t=0`;
    iframe.style.height = '100%';
    iframe.style.width = '100%';
    iframe.frameBorder = '0';
    iframe.allow = 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture';
    iframe.allowFullScreen = true;
    video.appendChild(iframe);
}