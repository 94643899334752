if ($(".countdown").length > 0) {
  $(".countdown").each(function() {
    const el = $(this);
    const date = new Date(el.data('date'));
    const completed = el.data('completed') || "It\'s here!!";

    const x = setInterval(() => {
      const now = new Date().getTime();
      const distance = date - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      el.text(days + "d " + hours + "h " + minutes + "m " + seconds + "s");

      if (distance < 0) {
        clearInterval(x);
        el.html(completed);
      }
    }, 1000);
  });
}

if ($(".toggle-childs").length > 0) {
  $(".toggle-childs").each(function() {
    let delay = 0;
    let duration = 5000;
    $(this).find('.item').each(function(i) {
      const el = $(this);
      el.hide();
      setTimeout(() => {
        el.fadeIn();
        setInterval(() => {
          el.fadeToggle();
        }, duration);
      }, delay);
      delay += duration;
    });
  });
}
