import consumer from "./consumer";

window.signatureCable = (id, callback) => {
    return consumer.subscriptions.create({
        channel: "SignatureChannel",
        id: id,
    }, {
        connected() {
            // Called when the subscription is ready for use on the server
        },

        disconnected() {
            // Called when the subscription has been terminated by the server
        },

        received(data) {
            callback && callback(data);
        },

        draw(data) {
            this.send({ message: data })
        }
    });
};
