const IS_APP_EVENT = "IsApp";
const GET_SCHEDULED_EVENT = "GetScheduled";
const WATCH_SCHEDULE_EVENT = "WatchSchedule";
const UNWATCH_SCHEDULE_EVENT = "UnwatchSchedule";

const postMessageToApp = (type, data = {}) => {
    window.parent.postMessage({type, ...data}, "*");
}

window.getScheduledEvents = () => {
    postMessageToApp(GET_SCHEDULED_EVENT);
};

window.watchSchedule = (id) => {
    postMessageToApp(WATCH_SCHEDULE_EVENT, {id});
};

window.unwatchSchedule = (id) => {
    postMessageToApp(UNWATCH_SCHEDULE_EVENT, {id});
};

window.isApp = false;
window.emulateApp = () => {
    window.dispatchEvent(new MessageEvent('message', {data: { type: 'IsApp', value: true } }));
}
postMessageToApp(IS_APP_EVENT);
window.addEventListener('message', (ev) => {
    switch (ev.data.type) {
        case IS_APP_EVENT:
            window.isApp = ev.data.value;
            if (window.isApp) {
                document.dispatchEvent(new CustomEvent("is-app"));
                $('.hide-app').hide();
            }
            break;
        case GET_SCHEDULED_EVENT:
            document.dispatchEvent(new CustomEvent("get-scheduled", {detail: ev.data.value}));
            break;
    }
});

let localHistory = [];
const localHistoryRaw = window.localStorage.getItem('history');
if (localHistoryRaw) {
    localHistory = JSON.parse(localHistoryRaw);
}
if (localHistory.length < 1 || localHistory[localHistory.length - 1] !== window.location.href) {
    localHistory.push(window.location.href);
}
window.localStorage.setItem('history', JSON.stringify(localHistory));
if (localHistory.length > 1) {
    $('.back-button').show();
}

window.goBack = () => {
    const prev = localHistory.splice(localHistory.lastIndexOf(window.location.href), 1);
    window.localStorage.setItem('history', JSON.stringify(localHistory));
    if (localHistory.length > 0) {
        window.location.href = localHistory[localHistory.length - 1];
    } else {
        window.location.href = '/';
    }
}