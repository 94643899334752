const mainNavigationContainer = document.getElementById("main-navigation-container");
const mainNavigation = document.getElementById("main-navigation");
const hasTopBanner = document.getElementsByClassName("top-banner").length > 0;
if (hasTopBanner && window.location.pathname === "/") {
    mainNavigation.classList.add('pt-8');
}
function onScroll() {
    mainNavigation.classList.remove('pt-8');
    if (mainNavigationContainer.getBoundingClientRect().top < 16) {
        mainNavigation.classList.add('fixed', 'shadow');
        if (hasTopBanner) {
            mainNavigation.classList.add('pt-6');
        }
    } else {
        mainNavigation.classList.remove('fixed', 'shadow', 'pt-6');
    }
}
window.addEventListener("scroll", onScroll);
onScroll();
