$(document.body).on('click', '.load-more', async function() {
    const el = $(this);
    el.prop('disabled', true);
    const loader = el.find('.loader');
    loader.removeClass('hidden');
    const nextPage = parseInt(el.data('page') || 1) + 1;
    const pages = el.data('pages');
    const destination = el.data('destination');
    const url = el.data('url');
    const destinationElement = document.getElementById(destination);
    try {
        const data = await fetch(`${url}?page=${nextPage}`, { headers: { "X-Ajax": 1 } }).then(res => res.text());
        if (!data) {
            el.remove();
            return;
        }
        const html = $(data);
        html.appendTo(destinationElement);
        el.data('page', nextPage);
        el.prop('disabled', false);
        loader.addClass('hidden');
        galleries.refresh();
        if (nextPage >= pages) {
            el.remove();
        }
    } catch (e) {
        console.error(e);
    }
});
