if ($("#days").length > 0) {
  $("a[data-target]").click(function (ev) {
    ev.stopPropagation();
    ev.preventDefault();
    const targetId = $(this).data("target");
    const targetElement = $(`#${targetId}`);
    $(".tab-day").hide();
    $(".link-day").removeClass("text-black");
    $(".link-day").addClass("text-neutral-500");

    $(".link-day").removeClass("border-b-2");
    $(this).addClass("text-black");
    $(this).removeClass("text-neutral-500");
    $(this).addClass("border-b-2");
    targetElement.show();
  });
  $("a[data-target=day-4]").click();
}

if ($(".manage-schedule").length > 0) {
  document.addEventListener("alpine:init", () => {
    Alpine.data("schedulesData", () => {
      return {
        isApp: false,
        scheduledEvents: [],

        init() {
          this.$watch("isApp", () => {
            this.loadSchedulesNotifications();
          });
          document.addEventListener("get-scheduled", (ev) => {
            this.scheduledEvents = ev.detail;
          });
        },

        loadSchedulesNotifications() {
          window.getScheduledEvents();
        },

        isActiveNotification(id) {
          return this.scheduledEvents.indexOf(id) >= 0;
        },

        toggleNotification(id) {
          if (this.isActiveNotification(id)) {
            this.scheduledEvents.splice(this.scheduledEvents.indexOf(id), 1);
            window.unwatchSchedule(id);
          } else {
            this.scheduledEvents.push(id);
            window.watchSchedule(id);
          }
        },
      };
    });
  });
}
