$(document).ready(function(){
    function fixVideoAspect() {
        const aspectRatio = 16 / 9;
        const isMobile = $(window).width() < 1024;
        if (isMobile) {
            const width = $(window).width();
            $(".landing-video").css('height', width / aspectRatio);
            $(".landing-video").css('width', '100%');
            return;
        }
        const maxHeight = $(window).height() - 160;
        const maxWidth = $(window).width() - 160;
        let videoHeight = Math.min(maxHeight, maxWidth / aspectRatio);
        let videoWidth = videoHeight * aspectRatio;
        if (videoHeight > maxHeight) {
            videoHeight = maxHeight;
            videoWidth = videoHeight * aspectRatio;
        }
        $(".landing-video").css('height', videoHeight);
        $(".landing-video").css('width', videoWidth);
    }
    fixVideoAspect();
    $(window).resize(fixVideoAspect);
});
